import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { HelperService } from '../helper/helper.service';
import * as Parse from 'parse';
import { Storage } from '@ionic/storage-angular';


@Injectable({
  providedIn: 'root'
})
export class StoreService {

  offlineStoresKey = 'offline-stores';

  constructor(
    private helper: HelperService,
    private storage: Storage
  ) { }

  getStores() {
    return from(this.storage.get(this.offlineStoresKey).then(async (offlineStores) => {
      if(offlineStores) {
        return JSON.parse(offlineStores);
      } else {
        try {
          let query = new Parse.Query('Store');
          let results = await query.skip(0).limit(1000).find()
          let storesAsJSON = this.helper.parseResultToJSON(results);
          this.storage.set(this.offlineStoresKey, JSON.stringify(storesAsJSON));
          return storesAsJSON;
        } catch(err) {
          return [];
        }
      }
    }, (err) => {
      return [];
    }));
  }

  getSingleStore(storeNumber) {
    return this.getStores().pipe(map(stores => {
      return stores.find(store => store.StoreNumber == storeNumber);
    }));
  }
}
